<template>
    <div class="flex h-full min-h-[200px] items-center justify-center">
        <img
            :src="`${
                useRuntimeConfig().public.staticUrl
            }/assets/img/loader/spinner.svg`"
            alt="spinner-loader"
            srcset=""
        />
    </div>
</template>
<script setup></script>
